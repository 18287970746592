<template>
  <a-layout style="padding: 0px">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        okText="确定"
        @ok="onout"
        :closable="false"
        :maskClosable="false"
        :visible="logvisible"
        :cancel-button-props="{ props: { disabled: true } }"
      >
        登录已失效，请前往重新登陆
      </a-modal>
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>财务管理</a-breadcrumb-item>
              <a-breadcrumb-item>核销审核</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout style="width: 100%; padding: 24px">
      <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0 }"
      >
        <div class="search-wrap">
          <a-tabs default-active-key="1" @change="callback">
            <a-tab-pane key="1" tab="待审批">
              <a-table
                :row-selection="rowSelection"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="flowAuditsearch"
              >
                <span slot="payType" slot-scope="text">
                  {{
                    text == 0
                      ? '支付宝'
                      : text == 1
                      ? '微信'
                      : text == 2
                      ? 'POS机'
                      : text == 3
                      ? '银行转账'
                      : text == 4
                      ? '现金'
                      : '其他'
                  }}
                </span>
                <span slot="time" slot-scope="text">
                  {{ text | time }}
                </span>
                <span slot="storeNames" slot-scope="text">
                  <a-tooltip>
                    <template slot="title">
                      {{ text }}
                    </template>
                    {{ text.length > 22 ? text.slice(0, 22) + '...' : text }}
                  </a-tooltip>
                </span>
                <span slot="signPartyBName" slot-scope="text, record">
                  {{ record.signPartyBName || record.intentionPartyBName }}
                </span>
                <template slot="action" slot-scope="text, record">
                  <a @click="onEdit(record)"> 审核 </a>
                </template>
              </a-table>
              <a-pagination
                v-model="params.current"
                :page-size="params.pageSize"
                show-quick-jumper
                :default-current="2"
                :total="total"
                @change="onpagesize1"
              />
            </a-tab-pane>
            <a-tab-pane key="2" tab="已审批" force-render>
              <a-table
                :row-selection="rowSelection"
                rowKey="id"
                :pagination="false"
                :columns="column"
                :data-source="flowAuditrecord"
              >
                <span slot="time" slot-scope="text">
                  {{ text | time }}
                </span>
                <span slot="passed" slot-scope="text">
                  {{ text == 1 ? '通过' : '拒绝' }}
                </span>
              </a-table>
              <a-pagination
                v-model="params.current"
                :page-size="params.pageSize"
                show-quick-jumper
                :default-current="2"
                :total="total"
                @change="onpagesize2"
              />
            </a-tab-pane>
          </a-tabs>
        </div>
        <a-modal
          @cancel="onClose"
          width="750px"
          title="账单信息"
          :visible="visible"
        >
          <a-descriptions
            v-if="billlist.intention == null"
            layout="vertical"
            title="合同信息"
          >
            <a-descriptions-item label="合同编号">
              {{ billlist.sign == null ? '' : billlist.sign.code }}
            </a-descriptions-item>
            <a-descriptions-item label="出租方">
              {{ billlist.sign == null ? '' : billlist.sign.partyAName }}
            </a-descriptions-item>
            <a-descriptions-item label="承租方">
              {{ billlist.sign == null ? '' : billlist.sign.partyBName }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions v-else layout="vertical" title="合同信息">
            <a-descriptions-item label="合同编号">
              {{ billlist.intention == null ? '' : billlist.intention.code }}
            </a-descriptions-item>
            <a-descriptions-item label="出租方">
              <a-tooltip>
                <template slot="title">
                  {{
                    billlist.intention == null
                      ? ''
                      : billlist.intention.partyAName
                  }}
                </template>
                {{
                  billlist.intention == null
                    ? ''
                    : billlist.intention.partyAName | text
                }}
              </a-tooltip>
            </a-descriptions-item>
            <a-descriptions-item label="承租方">
              <a-tooltip>
                <template slot="title">
                  {{
                    billlist.intention == null
                      ? ''
                      : billlist.intention.partyBName
                  }}
                </template>
                {{
                  billlist.intention == null
                    ? ''
                    : billlist.intention.partyBName | text
                }}
              </a-tooltip>
            </a-descriptions-item>
          </a-descriptions>

          <a-descriptions layout="vertical" :column="4" title="账单信息">
            <a-descriptions-item label="账单日期">
              {{ billlist.billDate }}
            </a-descriptions-item>
            <a-descriptions-item label="支付日期">
              {{ billlist.payDate }}
            </a-descriptions-item>
            <a-descriptions-item label="已支付金额">
              {{ billlist.payed == null ? '0' : billlist.payed }}元
            </a-descriptions-item>
            <a-descriptions-item label="账单金额">
              {{ billlist.totalFee }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions layout="vertical" :column="4" title="核销信息">
            <a-descriptions-item label="付款人">
              {{ list.payByName }}
            </a-descriptions-item>
            <a-descriptions-item label="付款金额">
              {{ list.fee }}元
            </a-descriptions-item>
            <a-descriptions-item label="付款时间">
              {{ list.payTime | time }}
            </a-descriptions-item>
            <a-descriptions-item label="交易单号">
              {{ list.code }}
            </a-descriptions-item>
            <a-descriptions-item label="提交时间">
              {{ list.createTime | time }}
            </a-descriptions-item>
            <a-descriptions-item label="支付方式">
              {{
                list.payType == '0'
                  ? '支付宝'
                  : list.payType == '1'
                  ? '微信'
                  : list.payType == '2'
                  ? 'POS机'
                  : list.payType == '3'
                  ? '银行转账'
                  : list.payType == '4'
                  ? '现金'
                  : '其他'
              }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ list.note }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions layout="vertical" :column="4">
            <a-descriptions-item label="凭证"
              ><div class="clearfix">
                <a-upload
                  @preview="handlePreview"
                  list-type="picture-card"
                  :file-list="proof"
                >
                </a-upload>
                <a-modal
                  width="750px"
                  :visible="previewVisible"
                  :footer="null"
                  @cancel="handleCancel"
                >
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </a-descriptions-item>
          </a-descriptions>
          <a-form-model ref="ruleForm" :model="form" :rules="rules">
            <a-form-model-item style="margin-top: -13px" label="审核备注">
              <a-input placeholder="请输入审核备注" v-model="form.note" />
            </a-form-model-item>
          </a-form-model>
          <template slot="footer">
            <a-button @click="ok" type="primary">通过</a-button>
            <a-button @click="cancel" type="danger"> 拒绝 </a-button>
            <a-button @click="onClose" type="dashed">取消</a-button>
          </template>
        </a-modal>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '铺位号',
    dataIndex: 'storeNames',
    scopedSlots: { customRender: 'storeNames' },
  },
  {
    title: '承租方',
    dataIndex: 'signPartyBName',
    scopedSlots: { customRender: 'signPartyBName' },
  },
  {
    title: '核销金额',
    dataIndex: 'fee',
  },
  {
    title: '付款人',
    dataIndex: 'payByName',
  },
  {
    title: '付款时间',
    dataIndex: 'payTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    scopedSlots: { customRender: 'payType' },
  },
  {
    title: '提交人',
    dataIndex: 'creatorName',
  },
  {
    title: '提交时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const column = [
  {
    title: '付款人',
    dataIndex: 'flow.payByName',
  },
  {
    title: '核销金额',
    dataIndex: 'flow.fee',
  },
  {
    title: '交易单号',
    dataIndex: 'flow.code',
  },
  {
    title: '提交时间',
    dataIndex: 'flow.updateTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '审核时间',
    dataIndex: 'time',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '审核结果',
    dataIndex: 'passed',
    scopedSlots: { customRender: 'passed' },
  },
]
const OSS = require('ali-oss')
let client
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
import { nanoid } from 'nanoid'
import http from '../../../http'
export default {
  data() {
    return {
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      previewVisible: false,
      previewImage: '',
      columns,
      column,
      params: {
        current: 1,
        pageSize: 10,
      },
      total: 0,
      billlist: {},
      list: [],
      proof: [],
      visible: false,
      logvisible: false,
      flowAuditsearch: [],
      flowAuditrecord: [],
      selectedRowKey: [],
      Id: '',
      rules: {
        note: [
          { required: true, message: '审核备注不能为空', trigger: 'blur' },
        ],
      },
      form: {
        id: '',
        passed: '',
        flowId: '',
        note: '',
      },
    }
  },
  filters: {
    text(value) {
      if (!value) return ''
      if (value.length > 14) {
        return value.slice(0, 14) + '...'
      }
      return value
    },
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    cancel() {
      if (this.flowAuditsearch.length <= 1) {
        this.params.current = this.params.current - 1
      }
      if (this.form.note == '') {
        this.$message.error('审核备注不能为空')
        return
      }
      this.form.passed = 0
      this.postfinanceAudit(this.form)
      this.visible = false
    },
    ok() {
      if (this.flowAuditsearch.length <= 1) {
        this.params.current = this.params.current - 1
      }
      this.form.passed = 1
      this.postfinanceAudit(this.form)
      this.visible = false
    },
    onClose() {
      this.visible = false
      this.Id = ''
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    callback(e) {
      this.params.current = 1
      if (e == 1) {
        this.getflowAuditsearch()
      } else {
        this.getflowAuditrecord()
      }
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },

    // 编辑
    onEdit(item) {
      this.list = item
      this.form.flowId = item.id
      //   console.log(item);
      this.proof = JSON.parse(item.proof)
      var a = []
      this.proof.forEach(item => {
        let url
        url = client.signatureUrl(item.name)
        a.push({
          bucket: 'sccncloud2',
          name: item.name,
          uid: nanoid(),
          status: 'done',
          url: url,
        })
      })
      this.proof = a
      this.getbillId(item.billId)
    },
    onpagesize2(e) {
      this.params.current = e
      this.getflowAuditrecord()
    },
    onpagesize1(e) {
      this.params.current = e
      this.getflowAuditsearch()
    },
    async getflowAuditrecord() {
      try {
        const res = await http.getflowAuditrecord(
          this.params.current,
          this.params.pageSize
        )
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.flowAuditrecord = data.audits.records
          this.total = data.audits.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async postfinanceAudit(form) {
      try {
        const res = await http.postfinanceAudit(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getflowAuditsearch() {
      try {
        const res = await http.getflowAuditsearch(
          this.params.current,
          this.params.pageSize
        )
        const { success, data } = res.data
        if (success) {
          this.flowAuditsearch = data.signs.records
          this.total = data.signs.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getbillId(id) {
      try {
        const res = await http.getbillId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.billlist = data.bill
          this.visible = true
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onout() {
      this.$router.push({ name: 'login' })
    },
    timer() {
      return setTimeout(() => {
        this.getflowAuditsearch()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
  },
  mounted() {
    this.getsts()
    this.getflowAuditsearch()
    const users = JSON.parse(localStorage.getItem('store-userlist'))
    if (users == null) {
      this.logvisible = true
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 0;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 600;
  font-size: 16px;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .anticon-delete {
  display: none;
}
</style>
